@import '../common/config.scss';

.an-card {
    border: none;
    box-shadow: none !important;
    margin: 10px;
    position: relative;
    width: calc(100% - 20px);

    &:hover {
        .an-card-image {
            transform: scale(1.1);
        }
    }

    &-labels {
        list-style-type: none;
        padding: 0;
        position: absolute;

        li {
            color: white;

            span {
                background-color: $anelma-color;
                border-radius: 20px;
                display: inline-block;
                height: 20px;
                margin: 2px 10px;
                padding: 2px 10px;
            }
        }
    }

    &-menu {
        position: absolute;
        right: 0;
    }

    .an-user-avatar {
        $size: 160px;
        box-shadow: 0 5px 5px rgba(0, 0, 0, 0.25);
        height: $size;
        margin: 10px auto;
        transition: 0.3s;
        width: $size;
    }
}
