@import '../../../config.scss';

.an-mobile-menu {
    background-color: #e8f1ef;
    bottom: 0;
    left: 0;
    overflow-y: auto;
    padding-bottom: 70px;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1000;

    &-close-container {
        height: $header-public-height;

        button {
            svg {
                font-size: 50px;
            }
        }
    }

    &-main-level {
        color: #799e8f;
        cursor: pointer;
        font-size: 40px;
        font-weight: 600;
        padding: 15px 24px;

        &-open {
            color: $anelma-color;
        }

        a {
            color: #799e8f;
        }

        svg {
            font-size: 40px;
            position: relative;
            top: 5px;
        }
    }

    &-sub-level {
        color: $anelma-color;
        font-size: 20px;
        padding: 0 24px;

        &-item {
            padding: 10px 0 10px 24px;
        }

        h4 {
            font-weight: 600;
        }

        a {
            color: $anelma-color;
        }
    }

    a {
        text-decoration: none;
    }
}
