@import '../../../config.scss';

.an-main-menu {
    @include bodyText();
    font-size: 20px;
    text-transform: uppercase;

    a,
    span {
        color: #fff;
        vertical-align: top;
    }

    a {
        text-decoration: none;
    }

    span {
        cursor: default;

        svg {
            position: relative;
            top: 5px;
        }
    }

    &-item {
        display: inline-block;

        &-open {
            opacity: 0.6;
        }

        .an-header-public & {
            margin-right: 37px;
        }

        .an-header-app & {
            margin-right: 20px;
        }

        &:last-of-type {
            margin-right: 2px;
        }
    }
}
