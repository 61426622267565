@import '../../../config.scss';

.an-header-open-mobile-menu {
    button {
        color: #fff;

        svg {
            font-size: 50px;
        }
    }
}
