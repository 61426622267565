.an-user-avatar {
    align-items: center;
    background: url('./default-avatar.png') center center no-repeat;
    background-size: cover;
    border-radius: 100%;
    display: flex;
    height: 36px;
    justify-content: center;
    overflow: hidden;
    text-align: center;
    width: 36px;

    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
}
