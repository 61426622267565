.an-content-image {
    margin: 0 0 20px 0;

    img {
        display: block;
        margin: 0 auto;
        max-width: 100%;
    }

    figcaption {
        margin-top: 10px;
        text-align: center;
    }

    &-border {
        float: left;
        margin: 0 20px 20px 0;
    }

    &-background {
        float: right;
        margin: 0 0 20px 20px;
    }

    &-streched {
        float: none;

        img {
            width: 100%;
        }
    }
}
