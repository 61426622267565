.animal-announcement {
    &-close-button-float-right {
        float: right;
    }
    &-grid-floating-bottom {
        position: sticky;
        bottom: 0;
        padding-bottom: 1em;
        background-color: white;
        z-index: 10;
    }
    &-button-content-position {
        padding: 5px;
        text-align: center;
    }
}
