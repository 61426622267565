.an-dialog-content {
    $margin: 32px;
    $xsWidth: 350px;
    $smWidth: 500px;
    $mdWidth: 700px;
    $lgWidth: 950px;
    $xlWidth: 1200px;

    &-xs {
        @media (min-width: #{$xsWidth + $margin * 2}) {
            width: $xsWidth;
        }
    }

    &-sm {
        @media (min-width: #{$xsWidth + $margin * 2}) {
            width: $xsWidth;
        }

        @media (min-width: #{$smWidth + $margin * 2}) {
            width: $smWidth;
        }
    }

    &-md {
        @media (min-width: #{$xsWidth + $margin * 2}) {
            width: $xsWidth;
        }

        @media (min-width: #{$smWidth + $margin * 2}) {
            width: $smWidth;
        }

        @media (min-width: #{$mdWidth + $margin * 2}) {
            width: $mdWidth;
        }
    }

    &-lg {
        @media (min-width: #{$xsWidth + $margin * 2}) {
            width: $xsWidth;
        }

        @media (min-width: #{$smWidth + $margin * 2}) {
            width: $smWidth;
        }

        @media (min-width: #{$mdWidth + $margin * 2}) {
            width: $mdWidth;
        }

        @media (min-width: #{$lgWidth + $margin * 2}) {
            width: $lgWidth;
        }
    }

    &-xl {
        @media (min-width: #{$xsWidth + $margin * 2}) {
            width: $xsWidth;
        }

        @media (min-width: #{$smWidth + $margin * 2}) {
            width: $smWidth;
        }

        @media (min-width: #{$mdWidth + $margin * 2}) {
            width: $mdWidth;
        }

        @media (min-width: #{$lgWidth + $margin * 2}) {
            width: $lgWidth;
        }

        @media (min-width: #{$xlWidth + $margin * 2}) {
            width: $xlWidth;
        }
    }

    &-xs,
    &-sm,
    &-md,
    &-lg,
    &-xl {
        .an-data-grid,
        .an-panel {
            margin: 0 20px 20px 20px;
        }
    }
}