@import '../../common/config.scss';

@media print {
    @page {
        size: landscape;
    }

    .invoice-to-print,
    .invoice-to-print * {
        visibility: visible;
    }

    .invoice-to-print {
        position: sticky;
        left: 10px;
        top: 8px;
        right: 10px;
        width: 1200px;
    }
}

.invoice-logo {
    width: 133px;
    height: 104px;
    margin: 0 auto auto auto;
    padding-left: 50px;
}
