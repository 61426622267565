.an-header-divider {
    background-color: rgba(255, 255, 255, 0.5);
    height: 38px;
    margin: 0 15px;
    width: 1px;

    .an-header-mobile & {
        margin-left: 30px;
    }

    @media (max-width: 360px) {
        margin: 0 10px;

        .an-header-mobile & {
            margin-left: 20px;
        }
    }
}
