@import './config.scss';

.an-panel {
    border-left: 4px solid $anelma-color;
    padding: 20px 40px;

    &.an-warning {
        border-left-color: $anelma-warning-color;
    }

    &.an-error {
        border-left-color: $anelma-error-color;
    }

    &-title {
        margin-bottom: 20px;

        & .MuiTypography-root {
            color: $anelma-color;

            .an-warning & {
                color: $anelma-warning-color;
            }

            .an-error & {
                color: $anelma-error-color;
            }
        }
    }
}
