@import '../../../config';

.an-header-bulletin {
    @include bodyText();
    background-color: #ffeb9c;
    color: $anelma-color;
    padding: 12px 0;
    text-align: center;

    &-content {
        .an-header-public &,
        .an-header-app & {
            padding-left: 120px;
            padding-right: 120px;
        }
    }
}
