@import '../../../_config.scss';

.an-mobile-header-logo {
    &-container {
        .an-header-public & {
            height: $header-public-height;
        }

        .an-header-app & {
            height: $header-app-height;
        }
    }

    background: url('../../snellman-logo.png') center center no-repeat;
    background-size: contain;
    display: block;
    position: relative;

    .an-header-app & {
        height: 100px;
        top: 11px;
        width: 129px;
    }

    .an-header-public &,
    .an-header-mobile & {
        height: 110px;
        top: 19px;
        width: 110px;
        left: 25px;
    }
}
