$anelma-color: #0a4c31;
$anelma-error-color: #f44336;
$anelma-warning-color: #cc6a0e;

$anelma-heading-font-family: 'Taberna', 'Montserrat', 'Arial';

$anelma-font-family: 'TheSans', 'PT Sans', 'Helvetica Neue', 'Helvetice', 'Roboto', 'Arial',
    'sans-serif';
$anelma-font-size: 18px;
$anelma-line-height: 1.5;

$header-public-height: 104px;
$header-app-height: 86px;

$header-margin-bottom: 30px;
$header-z-index: 1;

@mixin bodyText() {
    font-family: $anelma-font-family;
    font-size: $anelma-font-size;
    font-weight: 400;
    line-height: $anelma-line-height;
}
