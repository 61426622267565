.an-phone-numbers{
    $type-width: 120px;

    position: relative;

    .an-phone-numbers-phone-number{
        display: inline-block;
        width: calc(100% - #{$type-width});
    
        .an-input-container{
            padding-right: 10px;
        }
    }
    
    .an-phone-numbers-type{
        display: inline-block;
        width: $type-width;
    
        .an-input-container{
            padding-left: 10px;
        }
    }
    
    .an-phone-numbers-delete{
        position: absolute;
        right: 0;
        top: 12px;
    }
}