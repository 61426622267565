@import '../common/_config.scss';

.an-content-editor {
    border: 2px dashed #888;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
    margin: 0 -10px;
    padding: 10px;
    position: relative;
    z-index: 0;

    .ce-block__content,
    .ce-toolbar__content {
        max-width: 100%;
    }

    .ce-toolbar__actions {
        right: 5px;
    }

    .ce-header {
        color: $anelma-color;
        margin: 0;
        padding: 0;
    }

    h1.ce-header {
        font-family: $anelma-heading-font-family;
        font-size: 2.5rem;
        font-weight: 300;
        line-height: 1.167;
    }

    h2.ce-header {
        font-family: $anelma-heading-font-family;
        font-size: 2rem;
        font-weight: 300;
        line-height: 1.2;
    }

    h3.ce-header {
        font-family: $anelma-heading-font-family;
        font-size: 1.75rem;
        font-weight: 400;
        line-height: 1.167;
    }

    h4.ce-header {
        font-size: 1.5rem;
        font-weight: 400;
        line-height: 1.235;
    }

    h5.ce-header {
        font-size: 1.25rem;
        font-weight: 400;
        line-height: 1.334;
    }

    h6.ce-header {
        font-size: 1rem;
        font-weight: 500;
        line-height: 1.6;
    }

    .cdx-nested-list {
        li {
            line-height: 1.5;
        }
    }

    ol.cdx-nested-list {
        padding-left: 16px;
    }

    .cdx-simple-image__picture {
        img {
            display: block;
            margin: 0 auto;
        }

        &--with-border {
            img {
                margin: 0 auto 0 0;
            }
        }

        &--with-background {
            background: none;
            padding: 0;

            img {
                margin: 0 0 0 auto;
            }
        }

        &-stretched {
            margin: 0 auto;
        }
    }
}
