.an-user-menu-cultures {
    color: #fff;
    display: inline-block;
    text-transform: uppercase;

    .button-normal {
        color: #fff;
        font-size: 20px;
        margin-left: -8px;
        margin-right: -8px;

        &-mobile {
            color: #799e8f;
            cursor: pointer;
            font-size: 40px;
            font-weight: 600;
            padding: 15px 24px;
        }
    }
}
