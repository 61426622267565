@import '../config.scss';

.an-textarea{
    &-container{
        position: relative;
    }

    &-counter{
        font-family: $anelma-font-family;
        font-size: 12px;
        position: absolute;
        right: 0;
        top: 0;

        .an-input-container + & {
            right: 40px;
        }
    }
}