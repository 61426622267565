@import '../config.scss';

$footer-background-color: #f2f0eb;
$footer-color: $anelma-color;

.an-footer {
    background-color: $footer-background-color;
    box-sizing: border-box;
    color: $footer-color;
    margin-top: 64px;
    min-height: 330px;
    padding-bottom: 64px;
    padding-top: 64px;

    &-logo {
        background-image: url('./snellman-logo.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        height: 104px;
        margin: 0 auto 32px auto;
        width: 133px;
    }

    &-text {
        margin: 0 auto 54px auto;
        text-align: center;
        width: 390px;

        @media (max-width: 400px) {
            width: calc(100% - 20px);
        }
    }

    &-links {
        font-weight: 600;
        margin-bottom: 40px;
        text-align: center;

        a {
            margin: 0 12px;
        }

        @media (max-width: 400px) {
            width: calc(100% - 20px);

            a {
                display: inline-block;
            }
        }
    }

    &-contact {
        text-align: center;

        &-name {
            font-weight: 600;
        }

        ul {
            padding: 0;

            li {
                border-right: 1px solid $anelma-color;
                display: inline-block;
                padding: 0 10px;

                &:last-child {
                    border-right: none;
                }
            }
        }
    }

    a {
        color: $footer-color;
        display: inline-block;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
}
