@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,500;1,500&family=PT+Sans:ital@0;1&display=swap');

@import './common/config';

.an-body {
    font-size: $anelma-font-size;
    min-height: calc(100vh - 650px);
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: $anelma-color;
}

h1,
h2,
h3 {
    font-family: $anelma-heading-font-family;
    text-transform: lowercase;
}

h1 {
    font-size: 56px;
    letter-spacing: 5;
    line-height: 63px;
}

h2 {
    font-size: 42px;
    line-height: 47px;
}

h3 {
    font-size: 32px;
    line-height: 36px;
}

h4,
h5,
h6 {
    font-family: $anelma-font-family;
}

h4 {
    font-size: 24px;
    line-height: 27px;
}

h5 {
    font-size: 18px;
    line-height: 27px;
}

h6 {
    font-size: 16px;
    line-height: 21px;
}
