.anelma-animal-announcement {
    &-general-label {
        padding-left: 40px !important;
        margin-bottom: 0.5em;
    }

    &-general-background-light-red {
        background-color: #ffcccc;
        height: 100%;
        min-height: 100%;
        width: 100%;
        min-width: 100%;
    }

    &-add-animal-txt-placeholder {
        padding-left: 40px;
        margin-top: 40px;
        margin-bottom: 10px;
    }
}
