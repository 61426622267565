#anelma-animals-animal-list-filter-container {
    z-index: 1;
    position: relative;

    .hovered-dropdown,
    .hovered-dropdown-icon,
    .hovered-main-option {
        &:hover {
            background-color: rgb(10, 76, 49, 0.1);
        }
        cursor: pointer;
    }
}

#anelma-animals-animal-list-container,
#anelma-animals-animal-list-grid-container {
    z-index: 0;
    position: relative;

    #carousel-wheel-start, #carousel-wheel-end {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100px;
        color: #0a4c31;
        cursor: pointer;
    }

    #carousel-wheel-start {
        align-items: flex-start;
    }

    #carousel-wheel-end {
        align-items: flex-end;
    }
}

.anelma-carousel-item {
    $border-color: #0a4c31;
    $default-bg-color: #e8f1ef;
    $default-bg-height: 100px;
    $selected-bg-color: #ffeb9c;

    border: 1px solid $border-color;
    height: $default-bg-height;
    background-color: $default-bg-color;

    &-drop-down {
        border: 1px solid $border-color;
        background-color: $default-bg-color;
    }

    &-selected {
        border: 1px solid $border-color;
        height: $default-bg-height;
        background-color: $selected-bg-color;
    }
    &-selected h6 {
        font-weight: bold;
    }
}
