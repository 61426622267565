@import '../../../config.scss';

.an-user-info {
    &-avatar {
        cursor: pointer;
        height: 100px;
        margin: 0 auto;
        position: relative;
        width: 100px;

        &:hover {
            .an-user-info-change {
                display: block;
            }
        }

        .an-user-avatar {
            border: 2px solid $anelma-color;
            height: 100%;
            width: 100%;
        }
    }

    &-change {
        background-color: rgba(0, 0, 0, 0.25);
        border-radius: 0 0 100px 100px;
        bottom: -2px;
        display: none;
        height: 50%;
        left: 2px;
        position: absolute;
        text-align: center;
        width: 100%;

        svg {
            color: #fff;
            font-size: 40px;
            margin-top: 5px;
        }
    }
}
