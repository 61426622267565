@import '../../../config.scss';

.an-sub-menu {
    display: flex;
    justify-content: center;
    &-container {
        background-color: #e8f1ef;
        position: absolute;
        width: 100%;
        z-index: 1;

        .an-header-public & {
            top: $header-public-height;
        }

        .an-header-app & {
            top: $header-app-height;
        }
    }

    &-item-placeholder {
        cursor: default;
        opacity: 0.5;
    }

    &-group {
        padding: 0 20px;
    }

    @include bodyText();
    padding: 70px 0;

    &.compact {
        padding: 20px;
    }

    a,
    h4,
    span {
        color: $anelma-color;
        font-size: 18px;
    }

    a {
        opacity: 1;
        text-decoration: none;
        white-space: nowrap;
    }

    h4 {
        font-size: 22px;
        font-weight: 600;
        padding: 0 16px 16px 16px;
        white-space: nowrap;
    }
}

.an-sub-menu-grid {
    display: flex;
    width: max-content;
    justify-content: center;
}
