.an-content-table {
    margin-bottom: 20px;
    width: auto !important;

    th {
        font-weight: 600;
    }

    .MuiTableCell-root {
        padding: 8px 16px;
    }

    tbody {
        tr:last-child {
            .MuiTableCell-root {
                border: none;
            }
        }
    }
}
