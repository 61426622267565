.an-header-login-link {
    color: #fff;
    cursor: pointer;
    vertical-align: middle;

    .an-user-avatar {
        .an-header-public & {
            display: inline-block;
            height: 54px;
            margin-left: 15px;
            vertical-align: middle;
            width: 54px;
        }
    }
}
