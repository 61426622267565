@import '../config';

.an-cookie-notification {
    background-color: #ffeb9c;
    bottom: 0;
    box-shadow: 0 -5px 20px rgba(0, 0, 0, 0.25);
    color: $anelma-color;
    left: 0;
    padding: 36px 0;
    position: fixed;
    right: 0;
    text-align: center;

    span {
        font-size: 20px;
    }

    button {
        margin-top: 24px;
    }
}
