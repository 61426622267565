@import '../config.scss';

$header-background-color: $anelma-color;

.an-header {
    align-content: center;
    display: flex;
    flex-direction: column;
    margin-bottom: $header-margin-bottom;
    position: relative;
    z-index: $header-z-index;

    &-top {
        background-color: $header-background-color;
        min-height: $header-app-height;
        z-index: 2;

        .an-header-public & {
            height: $header-public-height;
        }

        .an-header-app & {
            height: $header-app-height;
        }

        &-container {
            display: flex !important;
            flex-flow: row wrap;
            position: relative;
            align-items: center;
        }
    }
}

.an-header-top-container {
    padding-left: 18px !important;
    padding-right: 18px !important;
}
