@import '../../../config.scss';

.an-user-menu {
    &-drawer {
        a {
            color: $anelma-color;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}
