@import '../common/config.scss';

.an-badge {
    border: none;
    box-shadow: none !important;
    display: block;
    margin: 10px 0;
    position: relative;

    .an-user-avatar {
        $img-size: 70px;
        box-shadow: 0 5px 5px rgba(0, 0, 0, 0.25);
        float: left;
        height: $img-size;
        margin-right: 20px;
        width: $img-size;
    }

    &-content {
        float: left;
        padding: 0 !important;
        width: calc(100% - 90px);
    }

    &-name,
    &-job {
        margin-bottom: 6px !important;
    }

    &-phone,
    &-email {
        font-size: 14px !important;
        margin-bottom: 3px !important;
    }

    &-labels {
        clear: both;
        display: block;
        list-style-type: none;
        padding: 10px 0 0 0;
        text-align: center;

        li {
            color: white;
            display: inline-block;

            span {
                background-color: $anelma-color;
                border-radius: 20px;
                display: inline-block;
                height: 20px;
                margin: 0 5px;
                padding: 2px 10px;
            }
        }
    }
}
