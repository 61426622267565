@import '../../../config.scss';

.an-user-menu-open {
    @include bodyText();
    color: #fff;
    cursor: pointer;

    .an-user-avatar {
        .an-header-app & {
            display: inline-block;
            height: 50px;
            margin-right: 15px;
            vertical-align: middle;
            width: 50px;
        }
    }
}
