@font-face {
    font-family: 'Taberna';
    src: url(./fonts/TabernaSerif-Regular.woff2) format('woff2'),
        url(./fonts/TabernaSerif-Regular.woff) format('woff'),
        url(./fonts/TabernaSerif-Regular.eot) format('eot');
}

@font-face {
    font-family: 'TheSans';
    src: url(./fonts/TheSansBW5Plain.woff2) format('woff2'),
        url(./fonts/TheSansBW5Plain.woff) format('woff'),
        url(./fonts/TheSansBW5Plain.eot) format('eot');
}
