@import '../config.scss';

.an-context-selector {
    margin: 10px 0 30px 0;
    text-align: right;

    &--selected {
        $size: 24px;

        color: $anelma-color;

        &-changeable {
            cursor: pointer;
        }

        &-name {
            display: inline-block;
            font-size: 20px;
            line-height: $size;
            vertical-align: top;
        }

        svg {
            font-size: $size;
            height: $size;
            line-height: $size;
            vertical-align: top;
            width: $size;
        }
    }

    &--none {
        $size: 24px;

        color: $anelma-color;
        font-size: 20px;
        line-height: $size;
    }
}
