.an-content-container {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
        margin-bottom: 20px;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        clear: both;
    }

    p {
        text-align: justify;
    }
}
